<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="employees"
          :search="search"
          :status="status"
          :filter-status="filterStatus"
          title="Pegawai"
          subtitle="Halaman untuk melihat total seluruh pegawai"
          add-btn="Tambah Data"
          :no-add-button="true"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @change-page="paginationHandler"
          @show-image="showImage"
          @live-search="searchHandler"
          @filter-status="handleFilterStatus"
          @show-detail="detailCertificate"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else>
    </SkeletonLoaderTable>
    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="employee.photo"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
    <ModalDialog
      :visible="modalCertificate"
      header="Sertifikat Guru"
      :hidden-actions="false"
      @close-modal="modalCertificate = false"
    >
      <template
        v-if="certificates.length"
        v-slot:body
      >
        <div
          v-for="(certificate,index) in certificates"
          :key="index"
        >
          <div
            class="d-flex py-3 align-center justify-space-between"
          >
            <div>
              <p class="mb-0 font-semibold">
                {{ certificate.name }}
              </p>
              <span>Nomor : {{ certificate.description }}</span>
            </div>
            <div>
              <v-chip
                v-if="checkExtension(certificate.file) === 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                :href="certificate.file"
                target="_blank"
              >
                Lihat Sertifikat
              </v-chip>
              <v-chip
                v-if="checkExtension(certificate.file) !== 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                @click="showCertificate(certificate.file)"
              >
                Lihat Sertifikat
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </template>
      <template
        v-else
        v-slot:body
      >
        Belum ada sertifikat
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="showImageCertificate"
      :width="800"
      @close="showImageCertificate = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeShowCertificate"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="certificate.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import DataTablePagination from '../../components/DataTablePagination.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  components: {
    DataTablePagination,
    ImageOverlay,
    ModalDialog,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      search: '',
      page: 1,
      totalItems: 1,
      totalPages: 1,
      width: 600,
      filterStatus: true,
      isLoadingTable: true,
      isLoadingData: false,
      imageOverlay: false,
      modalCertificate: false,
      certificates: [],
      certificate: {
        file: '',
      },
      showImageCertificate: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'NIP', value: 'nip' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Jabatan', value: 'status_work' },
        { text: 'Username', value: 'username' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Alamat', value: 'place.address' },
        { text: 'Foto', value: 'image' },
        { text: 'Total Sertifikat', value: 'total_certificate' },
        { text: 'Sertifikat', value: 'detail' },
      ],
      status: [
        {
          id: 0,
          name: 'Tidak Aktif',
        },
        {
          id: 1,
          name: 'Aktif',
        },
      ],
      employees: [],
      employee: {
        photo: null,
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.listEmployee()
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    this.getSchoolUuid()
    await this.listEmployee()
    this.isLoadingTable = false
  },
  methods: {
    handleFilterStatus(status) {
      if (status !== null) return this.listEmployee({ is_active: status })

      return this.listEmployee()
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },

    async listEmployee(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('employee', { ...params, page: this.page, school: this.school_uuid }).then(
        ({ data }) => {
          this.employees = data.data.map((employees, index) => ({
            ...employees,
            index: index + 1,
          }))

          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(employee) {
      this.listEmployee({ employee })
    },

    async showImage(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(
        ({ data }) => {
          this.employee.photo = data.data.image
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },
    async detailCertificate(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        this.certificates = data.data.certificate
      })
      this.modalCertificate = true
    },
    async showCertificate(file) {
      this.certificate.file = file
      this.modalCertificate = false
      this.showImageCertificate = true
    },
    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
    closeShowCertificate() {
      this.overlay = false
      this.showImageCertificate = false
      this.modalCertificate = true
    },
    checkExtension(certificate) {
      const file = certificate.split('.')

      const last = file[file.length - 1]

      return last
    },
  },
}
</script>

<style>
</style>
